@import '../../assets/scss/config';

.paper {
  background-color: #ffffff;
  border: '1px solid #000';
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  width: 70%;
  border-radius: $border-radius;
  padding: 15px 30px 20px;
}

.paper:focus {
  outline: none;
}

$sizes: 40, 50, 60, 70, 80;

@each $size in $sizes {
  .modal-#{$size} {
    width: $size * 1%;
  }
}

@media (max-width: 600px) {
  @each $size in $sizes {
    .modal-#{$size} {
      width: 90%;
    }
  }
}

.paper h2 {
  font-size: 1.4rem;
}

.paper,
.paper p {
  font-size: 0.9rem;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}
