.root {
	padding-top: 56px;
	height: 100%;
	background-color: #f4f6f8 !important;
}

.shiftContent {
	padding-left: 240px;
}

.content {
	min-height: calc(100% - 70px) !important;
}

@media (min-width: 600px) {
	.root {
		padding-top: 64px;
	}
}

.toolbar_root {
	box-shadow: 0px 4px 8px -5px rgba(122, 122, 122, 0.4) !important;
}
