@import '../../assets/scss/config';

.inputContainerStyle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.labelDesktopStyle {
  font-size: 0.85rem;
  font-weight: 400;
  width: 180px;
  word-wrap: break-word;
  box-sizing: border-box;
  flex-shrink: 0;
}

@media (max-width: 500px) {
  .labelDesktopStyle {
    display: none;
  }
}

@media (min-width: 576px) {
  .inputContainerStyle fieldset legend {
    // display: none;
    width: 0 !important;
  }
}

.rootOutlinedStyle {
  font-weight: bold !important;
  > div > select > option {
    padding: 5px 10px !important;
  }
  > div > select:not(:checked) {
  }
  > div > select option:checked {
    background: $color-select-multiple -webkit-linear-gradient(bottom, $color-select-multiple
          0%, $color-select-multiple 100%);
    color: white !important;
  }
}

.rootStyle {
  // background-color: black !important;
}

// .selectStyle {
//   // background-color: black !important;
//   > option {
//     background-color: black !important;
//     color: white !important;
//   }
//   > option:checked {
//     background-color: rgb(63, 184, 38) !important;
//     color: white !important;
//   }
// }

// option:checked { color: red; }
