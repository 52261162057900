.row {
  height: 42px;
  display: flex;
  align-items: center;
  margin-top: 2px;
}

.spacer {
  flex-grow: 1;
}

.importButton {
  margin-right: 10px;
}

.exportButton {
  margin-right: 10px;
}

.searchInput {
  margin-right: 10px;
}
