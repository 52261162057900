.groupLabel {
  flex-direction: row !important;
  width: 100%;
}

.labels {
  display: flex;
  margin-top: 3px;
  min-width: 20%;
  align-items: center;
  padding-bottom: 5px;
  cursor: pointer;
}

.processBox {
  min-width: 33%;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 10px;
  .labels {
    display: flex;
    margin-top: 0px;
    margin-bottom: 0px;
    min-width: 20%;
    align-items: center;
    padding-bottom: 0px;
    cursor: pointer;
  }
}
