.root {
	height: 100%;
	display: flex;
	padding: 16px 4px;
	flex-direction: column;
	background-color: #ffffff;
}

.buttonDrawerStyle {
	text-align: center;
}

@media (min-width: 992px) {
	.drawer {
		height: calc(100% - 64px) !important;
		margin-top: 64px;
	}
}

.drawer {
	width: 240px;
}

.divider {
	margin: 16px 0px !important;
}

.nav {
	margin-bottom: 16px;
}
