.root {
	height: 100%;
	// background-color: #0ca189;
}

.content {
	height: 100%;
}

.header {
	box-shadow: none;
}
