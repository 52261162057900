.root {
	height: 100%;
	background-color: #0ca189;
	padding-top: 64px;
}

.content {
	height: 100%;
}

.header {
	box-shadow: none;
}

.toolbar_root {
	box-shadow: 0px 4px 8px -5px rgba(122, 122, 122, 0.4) !important;
}

.topBarDropdown {
	display: flex;
	flex-direction: row;
	align-items: center;
}
