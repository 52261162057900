@import '../../assets/scss/config';

.root {
  height: 100%;
	padding: 20px;
	border-radius: $border-radius !important;
}

.red {


}

.orange {
  background-color: #fabf3f !important;
}

.green {

}


.container {
  justify-content: space-between;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  box-sizing: border-box;
}

.item {
  margin: 0;
  box-sizing: border-box;

  h2{
    color: #ffffff !important;
  }
}

.title {
  font-size: 1rem !important;
  font-weight: 500 !important;
  // text-transform: uppercase;
  color: #ffffff !important;
}


.icon {
  height: 55px !important;
  width: 55px !important;
  color: #ffffff;
}


.h2ValueStyle{
	font-size: 35px !important;
	font-weight: 700;
}
