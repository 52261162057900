@import '../../assets/scss/config';

.paperStyle {
  min-width: 400px !important;
}

.title h2 {
  font-size: 1.4rem;
}

p.description {
  font-size: 0.9rem;
}

.wrapper {
  margin: 10px;
  position: relative;
}

.buttonProgress {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
}

.confirmStyle {
  background-color: $color-secondary !important;
}

.refuseStyle {
  background-color: $color-danger !important;
}
