@import '../../assets/scss/config';

.root {
	padding: 20px;
}

.link {
	color: $color-primary;
}

.content {
	padding-top: 50px;
	text-align: center;
}

.image {
	margin-top: 50px;
	display: inline-block;
	max-width: 100%;
	width: 200px;
}
