@import '../../../assets/scss/config';

// .inputContainerStyle {
// 	display: flex;
// 	align-items: center;
// 	justify-content: flex-start;
// }

// .inputBorderContainer{
// 	div{
// 		border-radius: $border-radius;
// 	}
// }

.labelDesktopStyle {
	font-size: 0.85rem;
	font-weight: 400;
	width: 180px;
	word-wrap: break-word;
	box-sizing: border-box;
	flex-shrink: 0;
}

@media (max-width: 500px) {
	.labelDesktopStyle {
		display: none;
	}
}
