@import '../../assets/scss/config';

.BreadCrumbs li a,
.BreadCrumbs li p {
  text-transform: capitalize;
  font-size: 0.75rem;
  color: $color-links;
}

.BreadCrumbs li a {
  font-weight: 600;
}

.description {
  margin-top: 8px;
  font-size: 0.85rem;
  margin-bottom: 0;
}
