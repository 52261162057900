.cardActions {
	justify-content: center;
}

.wrapper {
	position: relative;
	//   width: 100%;
	// text-align: center;
	// margin-top: 20px;
}
