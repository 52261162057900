@import '../../assets/scss/config';

.headerContainer {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
}

.button {
  margin: 8px;
}
