.inputContainerStyle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.labelDesktopStyle {
  font-size: 0.85rem;
  font-weight: 400;
  width: 180px;
  word-wrap: break-word;
  box-sizing: border-box;
  flex-shrink: 0;
}

@media (max-width: 500px) {
  .labelDesktopStyle {
    display: none;
  }
}

@media (min-width: 576px) {
  .inputContainerStyle fieldset legend {
    // display: none;
    width: 0 !important;
  }
}

.dateRangePickerDialog {
  &.MuiPickersCalendar-transitionContainer {
    min-height: 218px;
    margin-top: 10px;
  }
}

.day {
  width: 40px;
  height: 36px;
  font-size: 0.75rem !important;
  margin: 0;
  color: #000000 !important;
  font-weight: 500;
  padding: 0;
  transition: none;
  &::after {
    border-radius: 100%;
    bottom: 0;
    box-sizing: border-box;
    content: '';
    height: 36px;
    width: 36px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    transform: scale(0);
    z-index: 2;
  }
  &:hover {
    background-color: transparent !important;
    color: #000000 !important;
    &::after {
      background-color: #ffffff !important;
      border: 2px solid #3f51b5 !important;
      bottom: -2px;
      left: -2px;
      height: 36px;
      width: 36px;
      right: -2px;
      top: -2px;
      box-sizing: content-box;
      transform: scale(1);
    }
  }
  & > span {
    z-index: 3 !important;
  }
}

.hidden {
  opacity: 0;
  pointer-events: none;
}

.current {
  color: rgb(153, 189, 17) !important;
  font-weight: 700;
}

$focusedRangeColor: rgba(63, 81, 181, 0.3);
$focusedRangeGradient: linear-gradient(
  to right,
  $focusedRangeColor,
  $focusedRangeColor
);
$transparentRangeGradient: linear-gradient(
  to right,
  rgba(0, 0, 0, 0),
  rgba(0, 0, 0, 0)
);

.focusedRange {
  color: #ffffff !important;
  background: $focusedRangeGradient no-repeat 0/20px 40px,
    $focusedRangeGradient no-repeat 20px 0/20px 40px !important;
  font-weight: 500;
  width: 40px;
  margin-right: 0;
  margin-left: 0;
  border-radius: 0 !important;
}

.dayDisabled {
  pointer-events: none;
  color: #cccccc;
}

.beginCap {
  &::after {
    transform: scale(1);
    background-color: rgb(63, 81, 181);
  }
}

.endCap {
  &::after {
    transform: scale(1);
    background-color: rgb(63, 81, 181);
  }
}

.focusedFirst {
  background: $transparentRangeGradient no-repeat 0/20px 40px,
    $focusedRangeGradient no-repeat 20px 0/20px 40px !important;
}

.focusedLast {
  background: $focusedRangeGradient no-repeat 0/20px 40px,
    $transparentRangeGradient no-repeat 20px 0/20px 40px !important;
}
