@import '../../assets/scss/config';

.containerStyle {
	border-radius: $border-radius;
	padding: 6px 10px;
	background-color: $color-label-chip;

	span {
		color: $color-label-chip-text;
	}
}

.containerTransparentStyle {
	border-radius: $border-radius;
	padding: 6px 10px;
	background-color: $color-background;

	span {
		color: $color-text-report-button;
	}
}
