@import '../../../assets/scss/config';

.EditionStyle {
  margin-bottom: 15px;
  transition: all 0.3s ease-out;

  // &> div {
  //   border-radius: $border-radius !important;
  // }
}
