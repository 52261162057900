@import '../../../../assets/scss/config';

.flexGrow {
	flex-grow: 1;
}

.popOverStyle {
	max-width: 300px !important;
}

.selectIcon {
	color: #575b6b !important;
}

.selectMenu {
	color: #575b6b !important;
}

.colortopbar {
	background: $color-top-bar !important;
}

.colorSecondary {
	background: $color-tertiary !important;
}

.logoTop {
	max-height: 40px;
}

.signOutButton {
	margin-left: 8px;
}

.formControl {
	margin: 2px !important;
	min-width: 220px !important;
}

.formControl > div:before {
	border-bottom: 1px solid #cccccc !important;
}

.formControl > div:hover:before {
	border-bottom: 2px solid #cccccc !important;
}

.HelperText {
	color: #cccccc !important;
	font-size: 0.55rem !important;
}

.label {
	color: #575b6b !important;
}

.topBarDropdown {
	display: flex;
	flex-direction: row;
	align-items: center;
}
