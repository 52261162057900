.root {
  padding: 0px 9px;
  display: flex;
  align-items: center;
  width: 400;
  margin-right: -12px;
}

.iconButton {
  padding: 8px !important;
}

button.iconButton:focus {
  outline: none;
  /* outline: 5px auto -webkit-focus-ring-color; */
}

.divider {
  height: 28px !important;
  margin: 4px !important;
}
