@import '../../assets/scss/config';

.root {
  background-color: $color-background;
  height: 100%;
}

@media (max-width: 991px) {
  .quoteContainer {
    display: none;
  }
}

.grid {
  height: 100%;
}

.logoImage {
  margin-left: 10px;
}

.quote {
  height: 100%;
  display: flex;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-image: url(/images/rio.jpg);
  background-repeat: no-repeat;
  background-position: center;
}

.quoteInner {
  flex-basis: 600px;
  text-align: center;
}

.quoteText {
  color: #ffffff !important;
  font-weight: 400 !important;
  text-shadow: 0px 0px 5px rgba(35, 65, 91, 0.9);
}

.name {
  color: #ffffff !important;
  margin-top: 8px !important;
  text-align: center;
  text-shadow: 0px 0px 5px rgba(35, 65, 91, 0.9);
}

.bio {
  color: #ffffff !important;
}

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.contentBody {
  display: flex;
  flex-grow: 1;
  align-items: center;
}

@media (max-width: 1279px) {
  .contentBody {
    justify-content: center;
  }
}

.contentHeader {
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-left: 16px;
  padding-right: 16px;
  //   padding-bottom: 16px;
}

.title {
  margin-top: 24px !important;
}

.socialButtons {
  margin-top: 24px !important;
}

.socialIcon {
  margin-right: 8px !important;
}

.sugestion {
  margin-top: 16px !important;
}

.form {
  flex-basis: 700px;
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 125px;
}

@media (max-width: 959px) {
  .form {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.textField {
  margin-top: 16px !important;
}

.wrapper {
  margin: 8px 0;
  position: relative;
}

.signUpButton {
  margin: 0px 0px !important;
}

.wrapperButton {
  position: relative;
  margin-top: 15px;
  text-align: center;
}

.progress {
  position: absolute;
  z-index: 10;
  color: white !important;
  //   top: 50%;
  margin-top: 8px;
  left: 50%;
}

.policy {
  display: flex;
  margin-top: 8px;
  align-items: center;
  padding-bottom: 20px;
}

.policyCheckbox {
  margin-left: -14px;
}
