@import '../../../assets/scss/config';

// .inputContainerStyle {
//   display: flex;
//   align-items: center;
//   justify-content: flex-start;
// }

.inputContainerStyle {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding-top: 5px;
	margin-bottom: 4px;

	fieldset {
		border-radius: $border-radius - 6 !important;
	}
  div.MuiOutlinedInput-input{
    padding-top: 10px !important;
  }
}

.errorText {
	position: relative;
	padding-left: 13px;
  margin-left: 10%;
}

.labelDesktopStyle {
  font-size: 0.85rem;
  font-weight: 400;
  width: 180px;
  word-wrap: break-word;
  box-sizing: border-box;
  flex-shrink: 0;
}

.textFieldRootStyle {
	fieldset {
		border-radius: $border-radius - 6 !important;
	}
}

@media (max-width: 500px) {
  .labelDesktopStyle {
    display: none;
  }
}

@media (min-width: 576px) {
  .inputContainerStyle fieldset legend {
    // display: none;
    width: 0 !important;
  }
}

.inputContainerStyle input {
  border-color: yellow;
}
