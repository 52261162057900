.jumbotron {
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  background-color: #f5f5f5;
  border-radius: 0.3rem;
}

.titleStyle {
  font-size: 0.95rem;
}

.jumbotron div {
  font-size: 0.75rem;
  text-align: justify;
}

.jumbotron ul {
  text-align: left;
}

.jumbotron ul li {
  display: inline;
  margin: 4px 10px;
}
