@import '../../assets/scss/config';
.addRegisterContainer {
  margin-top: 13px;
  background-color: rgba(129, 158, 182, 0.08);
  border: 1px solid rgba(129, 158, 182, 0.5);
  padding: 10px 10px 6px;
  border-radius: 4px;
}

.registerAddedContainer {
  background-color: #fff;
  padding: 8px 20px;
  border-radius: 4px;
  margin-top: 5px;
  table tr td,
  table tr th {
    font-size: 0.8rem;
  }
}

.addTitleAdditional {
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 1rem;

}

.amount {
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin-bottom: 10px;
  font-weight: 100;
  font-size: 1rem;
}

.buttonContainer{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.errorField {
  font-size: 0.7rem;
  color: red;
  margin-top: 10px;
}

.loaded {
  color: green;
}

.empty {
  color: red;
}

.icon {
  opacity: 0.9;
  font-size: 4rem !important;
  color: $color-secondary !important;
}

.containerWindow{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.voucherContainer{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  border: 2px solid rgba(129, 158, 182, 0.5);
  border-radius: 6px;
  
}

.headerVoucher {
  border-radius: 2px;
  text-align: center;
  color: #fff;
  background-color: rgb(47, 83, 116);
  text-transform: uppercase;
}

.infoVoucher{
  margin: 1% 0;
  text-align: center;
  margin-bottom: 8px;
  font-weight: 100;
  font-size: 1rem;
}


.titleDetail {
  text-transform: uppercase;
  font-weight: bolder ;
}

.download {
  padding: 10px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.downloadLink {
  margin-top: 20px;
}

