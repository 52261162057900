@import './config';

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	height: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: $font-size; /* 62.5% of 16px = 10px */
}

body {
	background-color: #f4f6f8;
	height: 100%;
	font-family: $font-stack--main;
	// font-size: 16px !important;
}

a {
	text-decoration: none;
}

#root {
	height: 100%;
}

#roottest {
	height: 100%;
	padding: 20px;
}

.MainCardRoot {
	box-shadow: none !important;
}

.MuiButton-root.MuiButton-contained.roundedButton {
	// border-radius: 20px;
}

.MuiButton-root.MuiButton-contained.bigButton {
	padding: 12px 22px;
	// border-radius: 25px;
}

.MuiCardContent-root .kJdJTV {
	min-height: 670px;
}

.table tr:first-child td,
.table tr:first-child th {
	border-top: none;
}

@media (max-width: 600px) {
	.table td,
	.table th {
		padding: 0.75rem;
		vertical-align: top;
		// border-top: none;
	}
}

.MuiGrid-root .ico-recomendation {
	width: 35px;
}

@media (max-width: 1500px) {
	.MuiGrid-root .ico-recomendation {
		width: 25px;
	}
}

@media (max-width: 1389px) {
	.MuiGrid-root .ico-recomendation {
		width: 20px;
	}
}

@media (min-width: 600px) and (max-width: 1375px) {
	.MuiGrid-root .img-estimated {
		display: none;
	}
}

@media (max-width: 600px) {
	.MuiGrid-root .img-estimated {
		margin-right: 10px;
	}
}

@media (min-width: 600px) and (max-width: 1375px) {
	.padding-col {
		padding-left: 5px;
		padding-right: 5px;
	}
}

// para columnas menos separadas, gutter de 10
.row.gutter_10 {
	margin-right: -10px;
	margin-left: -10px;
}
.gutter_10 > [class^='col-'],
.gutter_10 > [class^=' col-'] {
	padding-right: 10px;
	padding-left: 10px;
}

.highcharts-axis-labels span {
	word-break: break-all !important;
	width: 75px !important;
	white-space: normal !important;
}
