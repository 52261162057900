.tableStyle {
  font-size: .8rem;

  button {
    padding: 5px;
  }

  tr > td {
    border-bottom: 1px dotted #e6e6e6;
  }
}