@import './config';

.form-style {
	padding: 18px 0px;

	.form-inline {
		// align-items: baseline;
	}

	.container-fluid .col-md-12 > .MuiPaper-rounded.MuiCard-root {
		// border-radius: $border-radius !important;

		// .MuiDivider-root {
		// 	background-color: transparent;
		// }
	}

	.react-select__value-container {
		padding-top: 10px;
	}
	.react-select__placeholder {
		font-style: italic;
		font-size: 14px;
		color: #263238;
	}
}
