@import '../../assets/scss/config';

.success {
  background-color: $color-secondary !important;
}
.error {
  background-color: $color-danger !important;
}
.info {
  background-color: $color-primary !important;
}
.warning {
  background-color: $color-warning !important;
}

.icon {
  font-size: 2rem;
}
.iconVariant {
  opacity: 0.9;
  margin-right: 10px;
}
.message {
  display: 'flex';
  align-items: center;
}

.margin {
  margin: 10px;
}
