@import '../../assets/scss/config';

.inputContainerStyle {
	// display: flex;
	// align-items: center;
	// justify-content: flex-start;
	padding-top: 5px;
	margin-top: 2px;
	margin-bottom: 5px;
}

.formControl {
}

.labelDesktopStyle {
	font-size: 0.85rem;
	font-weight: 400;
	width: 180px;
	word-wrap: break-word;
	box-sizing: border-box;
	flex-shrink: 0;
}

.errorText {
	position: relative;
	padding-left: 13px;
}

@media (max-width: 500px) {
	.labelDesktopStyle {
		display: none;
	}
}

@media (min-width: 576px) {
	.inputContainerStyle fieldset legend {
		// display: none;
		width: 0 !important;
	}
}
