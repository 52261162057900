.root {
	display: flex;
	// flex-direction: column;
	align-items: center;
	justify-content: center;
	// min-height: fit-content;
	// padding-left: 12px;
	min-height: 40px;

	h5 {
		text-align: left;
	}

	p {
		text-align: center;
	}
}

.avatar {
	width: 60;
	height: 60;
}

.name {
	margin-top: 10px !important;
}
