.horizontalStyle {
  display: flex !important;
  flex-wrap: wrap !important;
  flex-direction: row !important;
}

.verticalStyle {
  display: flex !important;
  flex-wrap: wrap !important;
  flex-direction: column !important;
}

.errorText {
  position: absolute;
  margin-top: -5px !important;
  padding-left: 13px;
}
