@import '../../assets/scss/config';

.root {
	flex-grow: 1;
	width: 100%;
	background-color: #ffffff;
}

.tabPanelStyle {
	min-height: 50vh;
	// border-bottom: 1px solid #cccccc;
	// box-shadow: 0px 2px 3px -1px rgba(0, 0, 0, 0.1),
	// 0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
}

// .indicator {
//   background: $color-primary !important;
// }

.rootTabs {
	// border-bottom: 2px solid $color-not-focus-tabs !important;
	opacity: 0.8 !important;
	font-weight: normal !important;
	&:focus {
		outline: none !important;
	}
	&.TabsSelected {
		// color: $color-fonts-primary !important;
		// background-color: $color-tabs !important;
		opacity: 1 !important;
	}
}

.TabsTextColor {
	// color: $color-fonts-primary !important;
}
